import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const SecondPage = ({ pageContext: { theme } }) => (
  <Layout>
    <Seo title="GXS stylesheet" />
    <div>
      <h1 className="text-6xl mb-8 font-bold">GXS stylesheet</h1>

      {/* COLORS */}
      <div className="mb-12">
        <h2 className="text-neon-300 text-3xl mb-4 font-bold">Colors</h2>
        <div className="flex flex-row">
          {Object.keys(theme.colors).map(c => (
            <div key={c}>
              {Object.keys(theme.colors[c]).map(s => (
                <div key={s} className="pb-6">
                  <div
                    className="w-[100px] h-[100px] mr-4 border border-x-black-500 mb-2"
                    style={{
                      backgroundColor: theme.colors[c][s],
                    }}
                  />
                  <p className="capitalize">
                    {c}-{s}
                  </p>
                  <p>{theme.colors[c][s]}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* TYPOGRAPHY */}
      <div className="mb-12">
        <h2 className="text-neon-300 text-3xl mb-4 font-bold">Typography</h2>

        {/* TYPOGRAPHY - Font Family */}
        <div className="mb-4 flex flex-row gap-8">
          {Object.keys(theme.fontFamily).map(m => (
            <div key={m} className="mb-4">
              <p className="capitalize">{m} font</p>
              <p
                className="capitalize text-4xl font-bold"
                style={{ fontFamily: theme.fontFamily[m] }}
              >
                {theme.fontFamily[m]}
              </p>
            </div>
          ))}
        </div>

        {/* TYPOGRAPHY - sizes */}
        <div className="flex flex-col">
          {Object.keys(theme.fontSize).map(f => (
            <div key={f}>
              <div className="mb-4">
                <p
                  className="font-bold"
                  style={{
                    fontSize: theme.fontSize[f],
                  }}
                >
                  Lorem ipsum dolor sit amet
                </p>
                <p className="text-black-100">
                  Fontsize - {f} : {theme.fontSize[f]}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* SPACING */}
      <div className="mb-12">
        <h2 className="text-neon-300 text-3xl mb-4 font-bold">Spacing</h2>
        <div>
          {Object.keys(theme.extend.spacing).map(s => (
            <div>
              <p>
                {s} : {theme.extend.spacing[s]}
              </p>
              <div
                key={s}
                className="bg-bolt-100 mb-4 flex align-middle"
                style={{ height: theme.extend.spacing[s] }}
              ></div>
            </div>
          ))}
        </div>
      </div>

      {/* COLUMNS */}
      <div className="mb-12">
        <h2 className="text-neon-300 text-3xl mb-4 font-bold">Columns</h2>

        <div>
          <p className="pb-4">1 Columns</p>
          <div className="columns-1 gap-[20px] mb-8">
            <div className="bg-candy-100 h-[300px]" />
          </div>

          <p className="pb-4">2 Columns</p>
          <div className="columns-2 gap-[20px] mb-8">
            <div className="bg-candy-100 h-[300px]" />
          </div>

          <p className="pb-4">3 Columns</p>
          <div className="columns-3 gap-[20px] mb-8">
            <div className="bg-candy-100 h-[300px]" />
          </div>

          <p className="pb-4">4 Columns</p>
          <div className="columns-4 gap-[20px] mb-8">
            <div className="bg-candy-100 h-[300px]" />
          </div>

          <p className="pb-4">5 Columns</p>
          <div className="columns-5 gap-[20px] mb-8">
            <div className="bg-candy-100 h-[300px]" />
          </div>

          <p className="pb-4">6 Columns</p>
          <div className="columns-6 gap-[20px] mb-8">
            <div className="bg-candy-100 h-[300px]" />
          </div>
        </div>
      </div>

      {/* COLORS */}
      <div>
        <h2 className="text-neon-300 text-3xl mb-4 font-bold">Buttons</h2>
        <div>
          <Link className="btn-primary" to="">
            Button
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
